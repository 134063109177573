import { BUILD_ID } from "../../analytics";
import { TransformDomain } from "../types";
import { ReclaimApi, Team } from "./client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export class TeamDomain extends TransformDomain<Team, Team> {
  /**
   * The team domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing team module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "Team";
  cacheKey = "team";

  get = () => this.domainApi.team.getCurrentTeam();
}
